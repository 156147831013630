import React from "react";
import ReactDOM from "react-dom";
import "typeface-carrois-gothic";
import "./index.css";
import ProductPage from "./ProductPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SuccessPage from "./SuccessPage";

ReactDOM.render(
    <Router>
        <Switch>
            <Route path="/status/:vendorId/:productId/:variantId/:orderId" children={<SuccessPage />} exact={true} />
            <Route path="/:vendorId/:productId/:variantId" children={<ProductPage />} />
            <Route path="/:vendorId/:productId/" children={<ProductPage />} />
        </Switch>
    </Router>
    ,
    document.getElementById("root")
);
