import React, {useEffect, useState} from "react";
import axios from 'axios';
import header_logo from './assets/barbershop-logo-no-white-600_250x@2x.webp';
import ProductImages from './ProductImages';
import ProductDescription from "./ProductDescription";
import AdditionalInformation from "./AdditionalInformation";
import Success from "./Success";
import {useHistory, useParams} from "react-router";
import ProductNamePrice from "./ProductNamePrice";
import Cancel from "./Cancel";
import {Motion, spring} from "react-motion";
import {getFilteredProductImages} from './getFilteredProductImages';


function SuccessPage() {
    const {productId, orderId, variantId, vendorId } = useParams();
    const history = useHistory();

    const [data, setData] = useState({product: {brand: {name:''},images: [], variants: [{price: 0}]}});
    const [status, setStatus] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const dev = true;
    const [orderName, setOrderName] = useState('');
    const [currentVariant, setCurrentVariant] = useState({id: 0, name: '', price: 0, compare_at_price: 0});
    const [variants, setVariants] = useState([]);
    const [currentName, setCurrentName] = useState(null);
    const [sizes, setSizes] = useState([]);
    const [colors, setColors] = useState([]);

    const api_url = 'https://test.api.ministore.helthjem.no';
    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function verifyStatus() {
            const statusRequest = await verifyPayment(orderId);
            setStatus(statusRequest.data);
            setOrderName(statusRequest.data.draftOrderId)


        }

        verifyStatus();
    }, [orderId]);

    const [images, setImages] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const result = await axios({
                    method: "GET",
                    url: `${api_url}/products/${productId}/vendors/${vendorId}`,
                }
            );
            setData(result.data);
            const filteredImages = getFilteredProductImages(vendorId, productId, null, result.data.product);
            // setImages(filteredImages);
            setVariants(result.data.product.variants);
            setCurrentVariant(result.data.product.variants.find(v => v.id === parseInt(variantId, 10)));
        }

        fetchData();
    }, [productId]);

    const verifyPayment = async (orderId) => {
        const req = await axios({
            method: "GET",
            url: `${api_url}/vipps/status/${orderId}/vendors/${vendorId}`,
        }).catch(() => alert('something went wrong'));

        return req;
    };
    const initialErrorStyle = () => {
        return {
            y: spring(295, {stiffness: 190, damping: 25})
        }
    };
    const finalErrorStyle = () => {
        return {
            y: spring(-45, {stiffness: 190, damping: 25})
        }
    };
    let modalStyle = openModal ? finalErrorStyle() : initialErrorStyle();
    const handleOpenModal = () => {
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    }
    console.log('asdasdasd:  ', data);
    return (
        <div className="ProductPage">
            <header className={`ProductHeader ${vendorId}`}><img src={header_logo} alt={`${vendorId} logo`}/></header>

            <div className="container">
                <div className={'left'}>

                    <ProductImages images={data.product.images} variant={currentVariant}/>
                </div>
                <div className={'right'}>
                    <ProductNamePrice product={data} variant={currentVariant}/>
                    <div style={{marginTop: `20px`}}/>
                    <AdditionalInformation/>

                    <Motion style={modalStyle}>
                        {({y}) => {
                            return (
                                <div className="modal" style={{transform: `translateY(${y}px)`}}>
                                    Vi gjør oppmerksom på at informasjon vi mottar som følge av at du gjennomfører et
                                    kjøp ikke
                                    lagres av Schibsted, men av Barbershop som er selger av produktet og trenger denne
                                    informasjonen for å sikre at du får en optimal handleopplevelse. <br/><br/>Dine
                                    personopplysninger
                                    benyttes derfor kun for å gjennomføre handelen og sikre at du får din leveranse til
                                    riktig
                                    sted og til rett tid. Vi forbeholder oss imidlertid retten til å avvise ordre når vi
                                    finner
                                    grunn til det.
                                    <button onClick={handleCloseModal}>OK</button>
                                </div>);
                        }
                        }
                    </Motion>
                    {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'SALE' &&
                    <Success orderName={orderName}/>}
                    {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'CAPTURE' &&
                    <Success orderName={orderName}/>}
                    {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'RESERVE' &&
                    <Success orderName={orderName}/>}
                    {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'CANCEL' &&
                    <Cancel orderName={orderName}/>}
                </div>
                <ProductDescription product={data} type={'full'}/>

            </div>
        </div>
    );
}


export default SuccessPage;
