import React from "react";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import {useParams} from "react-router";

const analytics = Analytics({
    app: 'Ministore',
    plugins: [
        googleAnalytics({
            trackingId: 'UA-184679173-1',
            anonymizeIp: true,
            cookieConfig: {
                cookieName: 'gaCookie',
                cookieDomain: 'embed.ministore.helthjem.no',
                cookieFlags: 'SameSite=None; Secure',
            }
        })
    ]
});

function Success(props) {
    const dev = true;
    const {productId, vendorId} = useParams();

    if (!dev) {

        analytics.page();
    }
    return (
        <div className="success">
            <strong>Takk for at du valgte å handle hos {vendorId}.</strong>
            <span>Du vil snart motta en e-post som bekrefter din ordre fra {vendorId}. Hvis du har noen spørsmål angående din ordre, vennligst kontakt {vendorId} direkte.<br /> <br />Ditt ordrenummer er: <br /><strong>{props.orderName}</strong></span>
        </div>
    );
}

export default Success;
