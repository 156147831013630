import React, {useEffect, useState} from "react";
import axios from 'axios';
import header_logo_barbershop from './assets/barbershop-logo-no-white-600_250x@2x.webp';
import header_logo_antonsport from './assets/anton_logo.png';
import ProductDescription from "./ProductDescription";
import AdditionalInformation from "./AdditionalInformation";
import {useHistory, useParams} from "react-router";
import {css} from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import ProductNamePrice from "./ProductNamePrice";
import ProductImages from "./ProductImages";
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import {Motion, spring} from "react-motion";
import ReactModal from 'react-modal';
import PaymentInformation from "./PaymentInformation";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";

const override = css`
  display: block;
  margin: 0 auto;
  position:absolute;
  margin-left:auto;
  margin-right:auto;
  left:0;
  right:0;
  width:60px;
  top:16px;
`;

function ProductPage() {
    const dev = true;
    const analytics = Analytics({
        app: 'Ministore',
        plugins: [
            googleAnalytics({
                trackingId: 'UA-184679173-1',
                anonymizeIp: true,
                debug: true,
                cookieConfig: {
                    cookieName: 'gaCookie',
                    cookieDomain: 'embed.ministore.helthjem.no',
                    cookieFlags: 'SameSite=None; Secure',
                }
            })
        ]
    });
    useEffect(() => {
        if (!dev) {
            analytics.page();
        }

    }, [dev, analytics])


    const api_url = 'https://test.api.ministore.helthjem.no';
    const {productId, vendorId, variantId} = useParams();
    const history = useHistory();
    const [data, setData] = useState({
        product: {
            images: [],
            variants: [{id: 0, name: '', price: 0, compare_at_price: 0}]
        }
    });
    const [openModal, setOpenModal] = useState(false);
    const [openPopup, setOpenPopup] = useState(true);
    const [error, setError] = useState(false);
    const [variants, setVariants] = useState([]);
    const [quantity] = useState(1);
    const [customerConsent, setCustomerConsent] = useState(true);
    const [authorizePaymentInProgress, setAuthorizePaymentInProgress] = useState(false);
    const [images, setImages] = useState([]);
    const [currentVariant, setCurrentVariant] = useState({id: 0, name: '', price: 0, compare_at_price: 0});
    const [sizes, setSizes] = useState([]);
    const [colors, setColors] = useState([]);
    const [currentName, setCurrentName] = useState(null);
    const [currentSize, setCurrentSize] = useState(null);

    useEffect(() => {

        async function fetchData() {
            const result = await axios({
                    method: "GET",
                    url: `${api_url}/products/${productId}/vendors/${vendorId}/`,
                }
            ).catch(() => {
                setError(true);
            });
            setData(result.data);
            // console.log('>>>>>>', result.data.product.variants);
            setVariants(result.data.product.variants);
        }

        fetchData().catch(() => {
            setError(true);
        });

    }, [productId]);
    // console.log(currentVariant);

    useEffect(() => {
        // console.log('>>>>', variants);

        if (variants.length) {
            if (!variantId) {
                setCurrentVariant(variants[0]);
                history.push(`/${vendorId}/${productId}/${variants[0].id}`);
                setCurrentName(variants[0].name);

            } else {
                setCurrentVariant(variants.find(v => v.id === parseInt(variantId, 10)));
                // console.log(variants);
                if (variants.length) {
                    setCurrentName(variants.find(v => v.id == variantId).name);
                }
            }

            setSizes(data.product.variants.filter(v => v.name === currentName));
            setColors(data.product.variants.filter(v => !!v.available).map(p => p.name));
        }

    }, [data, variants, currentName]);
    const makePayment = async () => {
        setAuthorizePaymentInProgress(true);
        if (!dev) {
            analytics.track('payButtonClicked', {category: 'Vipps Buton', label: 'vipps button clicked'},);
        }

        const req = await axios({
            method: "POST",
            url: `${api_url}/vipps/vendors/${vendorId}`,
            data: {
                price: parseFloat(currentVariant.price),
                createCustomerConsent: customerConsent,
                title: `${data.product.title} (${currentVariant.name}/${currentVariant.size})`,
                quantity: 1,
                variant_id: currentVariant.id,
                product_id: productId
            }
        }).catch(() => alert('payment failed'));

        if (req && (req.status === 200 || req.status === 201) && !req.hasOwnProperty("errorCode")) {
            window.location = req.data.url;

        } else {
            alert('payment failed');
        }

    };
    const initialErrorStyle = () => {
        return {
            y: spring(295, {stiffness: 190, damping: 25})
        }
    };
    const finalErrorStyle = () => {
        return {
            y: spring(-45, {stiffness: 190, damping: 25})
        }
    };
    let modalStyle = openModal ? finalErrorStyle() : initialErrorStyle();

    const handleCloseModal = () => {
        if (!dev) {
            analytics.track('modal', {category: 'PrivacyModal', label: 'close'});
        }
        setOpenModal(false);
    }

    const handleClosePopup = () => {

        setOpenPopup(false);
    }

    const handleConsentChange = () => {
        if (customerConsent) {
            setCustomerConsent(false);
        } else {
            setCustomerConsent(true);
        }
    }
    const changeColor = (color) => {
        const newVariant = variants.find(p => p.name === color);
        history.push(`/${vendorId}/${productId}/${newVariant.id}`);
        setCurrentVariant(newVariant.id);
        setCurrentName(color);
    }

    const header_logo = vendorId === 'antonsport' ? header_logo_antonsport : header_logo_barbershop;
    const setSize = (size) => {
        setCurrentSize(size);
        setCurrentVariant(size);

    }
    // console.log('currentVariant: ', currentVariant);
    return (
        <>
            {!error && <div className="ProductPage">
                <div className="container">
                    <header className={`ProductHeader ${vendorId}`}><img src={header_logo} alt={`${vendorId} logo`}/>
                    </header>
                    <ProductNamePrice product={data} variant={currentVariant} mobile/>

                    <div className={'left'}>
                        <ProductImages images={data.product.images} variant={currentVariant}/>
                        {[...new Set(colors)].length > 1 && <div className={'choose-color'}>
                            Velg farge ({currentName}):
                            <ul>
                                {[...new Set(colors)].map(v => {
                                    return (
                                        <li key={v} onClick={() => changeColor(v)}
                                            className={`available-true ${currentName === v ? 'selected' : ''}`}
                                        ><img
                                            src={variants.find(p => p.name === v).thumbnail}/></li>
                                    )
                                })}
                            </ul>

                        </div>}

                        {sizes.length > 1 && <div className={'choose-size'}>
                            VELG STØRRELSE
                            <ul>
                                {[...new Set(sizes)].map(v => {
                                    return (
                                        <li
                                            className={`available-${v.available} ${!!currentSize && currentSize.id === v.id ? 'selected' : ''}`}
                                            onClick={() => {
                                                if(!!v.available) {
                                                    setSize(v);
                                                }
                                            }}
                                            key={v.id}>{v.size}</li>
                                    )
                                })}

                            </ul>
                        </div>}
                    </div>
                    <div className={'right'}>
                        <ProductNamePrice product={data} variant={currentVariant}/>
                        <ProductDescription product={data} type={'short'}/>


                        {/*<ShippingDetails/>*/}
                        <AdditionalInformation/>

                        <div className={"button-wrapper"}>
                            <span id={'where-buy'}>Kjøp nå fra Anton Sport</span>
                            <button className="button vipps"
                                    disabled={!data.product.images.length || authorizePaymentInProgress}
                                    onClick={makePayment}>Vipps
                                Hurtigkasse
                            </button>
                            <PulseLoader
                                css={override}
                                size={15}
                                color={"white"}
                                loading={authorizePaymentInProgress}
                            />
                            <div className={'checkbox-wrapper'}>
                                <Checkbox
                                    icon={<Icon.FiCheck color="#008000" size={12}/>}
                                    name="createCustomerConsent"
                                    checked={customerConsent}
                                    onChange={(value) => {
                                        handleConsentChange(value);
                                    }}
                                    size={17}
                                    borderRadius={3}
                                    borderWidth={2}
                                    borderColor="#008000"
                                    style={{cursor: "pointer"}}
                                    labelStyle={{marginLeft: 5, userSelect: "none"}}
                                    label="Jeg godkjenner at dere oppretter en gratis Anton Club konto dersom jeg ikke allerede har en."
                                />
                            </div>
                        </div>
                    </div>
                    <ProductDescription product={data} type={'full'}/>
                    <PaymentInformation/>

                </div>
                <Motion style={modalStyle}>
                    {({y}) => {
                        return (
                            <div className="modal" style={{transform: `translateY(${y}px)`}}>
                                Vi gjør oppmerksom på at informasjon vi mottar som følge av at du gjennomfører et kjøp
                                ikke
                                lagres av Schibsted, men av Barbershop som er selger av produktet og trenger denne
                                informasjonen for å sikre at du får en optimal handleopplevelse. <br/><br/>Dine
                                personopplysninger
                                benyttes derfor kun for å gjennomføre handelen og sikre at du får din leveranse til
                                riktig
                                sted og til rett tid. Vi forbeholder oss imidlertid retten til å avvise ordre når vi
                                finner
                                grunn til det.
                                <button onClick={handleCloseModal}>OK</button>
                            </div>);
                    }
                    }
                </Motion>
                <ReactModal
                    isOpen={false}
                >
                    Velkommen til en trygg handel med Schibsted hurtigkasse. Her kan du trygt handle varer på en enklere
                    måte.<br/><br/> Dersom du har spørsmål angående løsningen, send en mail
                    til: <strong>hurtigkasse@schibsted.no</strong>
                    <button onClick={handleClosePopup}>OK</button>
                </ReactModal>
            </div>}
            {error && <div className="ProductPage">
                <div className="container">
                    <header className={`ProductHeader ${vendorId}`}><img src={header_logo} alt={`${vendorId} logo`}/>
                    </header>
                    {/*<ProductNamePrice product={data} variantId={currentVariant} mobile/>*/}
                    <div className={'left'}>
                        {/*<ProductImages images={data.product.images} variant={currentVariant}/>*/}
                    </div>
                    <div className={'right'}>
                        <h1>Opps…</h1>
                        <div className={'product-price'} style={{fontWeight: "normal"}}>
                            vi kan ikke finne produktet du er ute etter.
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
}


export default ProductPage;
